import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    partner: "",
    whichToShow: "Dogs",
    day_block: "",
    partnerURL: "https://dog.agentthree.virtualbet3.com/graphql",
    horsePartnerURL: "https://horse.agentthree.virtualbet3.com/graphql",
  },
  getters: {},
  mutations: {
    loginPartner(state, partner) {
      state.partner = partner;
    },
    day_block(state, day_block) {
      state.day_block = day_block;
    },
    wichToSwitch(state, whichToShow) {
      state.whichToShow = whichToShow;
    },
  },
  actions: {
    loginPartner(context, partner) {
      context.commit("loginPartner", partner);
    },
    day_block(context, day_block) {
      context.commit("day_block", day_block);
    },

    wichToSwitch(context, whichToShow) {
      context.commit("wichToSwitch", whichToShow);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
